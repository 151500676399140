<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">单位管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">单位列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">解除绑定</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                label="绑定日期"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                width="160px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | createTime }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="unbind(scope.row)"
                    >解除绑定</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "zUnbind",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {},
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        compId: this.$route.query.compId
      };
      this.doFetch({
        url: "/sys/company/querySubscribeCompList",
        params,
        pageNum,
      });
    },
    // 解除绑定
    unbind(row) {
      this.$post("/sys/company/canalCompBind", {
        subId: row.subId,
      }).then((res) => {
        if (res.status == 0) {
          this.$message({
            message: "解绑成功",
            type: "success",
          });
          this.getData();
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.shell {
  width: 100%;
  overflow: hidden;
  .l {
    width: 30%;
    float: left;
  }
  .r {
    width: 70%;
    float: left;
  }
  .p2 {
    margin-top: 20px;
  }
}
</style>